<template>
    <div class="home">
        
        <div class="map-container">
            <DataMap 
                :key="mapKey" 
                :cameras="cameraDeployments" 
                :alerts="currentAlerts"
                :devices="devicesToDisplay"
                :center="mapCenter"
                @cameraClicked = "onCameraClicked"
                @viewGraph = "onViewGraph"
                />
        </div>

        <b-modal 
            id="settings-modal" 
            title="Graph Settings"
            ok-only="true"
            >
            <label>Time Period:</label>
            <b-form-select size="sm"  v-model="selectedHours" >
                
                <option disabled value="">Please select one</option>
                <option v-for="item in Object.keys(timePeriods)" :value="timePeriods[item]" :key="item">{{item}}</option>
            </b-form-select><br>
            <label>Data Type:</label>
            <b-form-select size="sm"  v-model="selectedDataType" >
                <option disabled value="">Please select one</option>
                <option v-for="item in dataTypes" :value="item" :key="item.id">{{item.dataType}}</option>
            </b-form-select><br>
        </b-modal>

        <!-- Draggable Video Player -->
        <!-- <Vue3DraggableResizable 
            class="info-section" 
       
            :minH="infoSectionHeight"
            v-model:h="infoSectionHeight"
            :disabledH = "true"
            :draggable="true"
            :resizable="true"
          
       
        >  
        <SnailVideoPlayer
            :cameraId="3"/>
        </Vue3DraggableResizable> -->
         
        <div class = "summary-section">
            <h3>Summary</h3>
            
            <b-list-group class="alerts-container">
                <hr style="width:95%;text-align:left;margin-left:2.5%; border: 1px solid grey">
                <b v-if="alertsInRadius.length ==0"> No alerts found<br> Try increasing the search radius<br> ... </b>
                
                <AlertMsg v-for="alert in alertsInRadius" :alert = "alert" :key="alert.id" @viewLocation="viewLocationOnMap"/>
            </b-list-group>
            
            <button class="expand-btn" @click="optionsExpanded = !optionsExpanded">
                <span :class="['expand-icon', {'expand-icon-up': optionsExpanded , 'expand-icon-down': !optionsExpanded }]"></span>
            </button>
            
            <div class="options-container" v-if="optionsExpanded">
                <b style=" margin-left:15px;text-align:left">Advanced Options: </b><br>
                <b-form-group label-size="sm" @change="getAlertsInArea" style=" margin-left:15px; text-align:left">
                
                    <label for="userLat">Latitude: </label>
                    <b-form-input size="sm" type="number" id="userLat" name="userLat" class="coords-input" step="0.001" v-model="userLat" style="align-self: right"/><br>
                    <label for="userLng">  Longitude: </label>
                    <b-form-input size="sm"   type="number" id="userLng" name="userLng" class="coords-input" step="0.001" v-model.number="userLng" /><br>
                    
                    <label>Period:</label>
                    <b-form-select size="sm"  v-model="selectedHours" >
                        <option disabled value="">Please select one</option>
                        <option v-for="item in Object.keys(timePeriods)" :value="timePeriods[item]" :key="item">{{item}}</option>
                    </b-form-select><br>
                    <label for="radiusInput"> Search Radius:  </label>
                    <b-form-input size="sm" type="number" id="searchRadius" name="searchRadius" class="coords-input" step="500" v-model="searchRadius" />
                </b-form-group>   
                <div style="display: grid; row-gap:10px">
                    <b-button @click="getUserLocation()" style="width: 100px; margin-left:25px">Use my current location </b-button>
                    <b-button @click="viewLocationOnMap(userLat, userLng)" style="width: 100px; margin-left:25px">Reset map </b-button>
                </div>
                
            </div>
                

        </div>

        <Vue3DraggableResizable 
            class="info-section" 
            v-for="deployment in plotDeployments"
            :key="deployment.id + deployment.type"
            :minH="infoSectionHeight"
            v-model:h="infoSectionHeight"
            :disabledH = "true"
            :draggable="true"
            :resizable="true"
            @resize-end="updatePlot()"
       
        >   
        
        <!-- no longer needed when responsive is set to true in plot config? -->
        <!-- @resize-end="updatePlot()" -->
            <!-- repurposed as myriota devices -->
            <b v-if="deployment.type == 'sensor'">{{ deployment.device.description }}</b>

            <!-- Note: this 'device' is different to the sensor devices previously -->
            <b v-if="deployment.type == 'camera'"> Device {{ deployment.camera.description[deployment.camera.description.length-1] }}</b>
            <span v-if="deployment.locationName">Deployed near <i>{{ deployment.locationName }}</i>  </span>    
            <!--Dragging/resizing seems to update the plots. Why???-->
            <span v-if="deployment.type == 'sensor'">
                <!-- <DataPlot  v-for="sensor in deployment.device.sensors" :key="'deployment#'+ deployment.id + 'sensor#' + sensor.id + plotKey" 
                    :data="{
                        'values':sensorData[deployment.id][sensor.id], 
                        'info': {
                                          
                            'title': sensor.description,
                            'type': sensor.type.description,
                            'unit': sensor.type.unit
                        }, 
                        'plotField': 'value',
                        'backgroundValues': alertsInRadius,
                        'backgroundField': 'level',
                    }"
                    :plotId="'deployment#'+ deployment.id + 'sensor#' + sensor.id"
                />-->

                <div v-if="dataLoading[deployment.device.terminalId] === null || dataLoading[deployment.device.terminalId] === true">
                    <h5>Loading data...</h5>
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                </div>
                <DataPlot v-else :key="'myriota#' + deployment.deviceId + plotKey" 
                    :data="{
                        'values':myriotaData[deployment.device.terminalId], 
                        'info': {                     
                            'title': selectedDataType.dataType + ' Over Time',
                            'type': selectedDataType.dataType,
                            'unit': selectedDataType.unit
                        },  
                        'plotField': 'value',
                     
                        'backgroundField': 'level',
             
                    }"
                    :plotId="'camera#' + deployment.deviceId"
                />
                
                <!--Might need to also remove sensor data to free up memory-->
                <!-- <b-button @click ="plotDeployments.splice(plotDeployments.indexOf(deployment),1);" style="width: 100px; ">Close</b-button> -->
            </span>
            <span v-if="deployment.type == 'camera'">
                <div v-if="dataLoading[deployment.camera.deviceId] === null || dataLoading[deployment.camera.deviceId] === true">
                    <h5>Loading data...</h5>
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                </div>
                <DataPlot v-else :key="'camera#' + deployment.cameraId + plotKey" 
                    :data="{
                        'values':environmentalData[deployment.camera.deviceId], 
                        'info': {                     
                            'title': selectedDataType.dataType + ' Over Time',
                            'type': selectedDataType.dataType,
                            'unit': selectedDataType.unit
                        }, 
                        'plotField': 'value',
                        'backgroundValues': currentAlerts[deployment.camera.deviceId],
                        'backgroundField': 'level',
             
                    }"
                    :plotId="'camera#' + deployment.cameraId"
                />
                <!--Might need to also remove sensor data to free up memory-->
                
            </span>
            
            

            <div class="graph-button-container">
                <b-button variant="light" style="width: 100px; position: relative;" v-b-modal.settings-modal>
                <b-icon icon="gear-fill" aria-hidden="true"></b-icon> Settings
                </b-button>

                <b-button @click ="plotDeployments.splice(plotDeployments.indexOf(deployment),1);" style="width: 100px;">Close</b-button>
            </div>
        </Vue3DraggableResizable>
      
    </div>
</template>

<script>

import DataMap from '@/components/DataMap.vue';
import DataPlot from '@/components/DataPlot.vue';
import AlertMsg from '@/components/AlertMsg.vue';
import SnailVideoPlayer from '@/components/SnailVideoPlayer.vue';
import Vue3DraggableResizable from 'vue3-draggable-resizable'
//default styles
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'
import geolocation from 'geolocation'
import SnailService from "../services/SnailService"
const snailService = new SnailService();
export default {
    name: 'HomeView',
    components:{
        DataMap,
        DataPlot,
        Vue3DraggableResizable,
        AlertMsg,
        //SnailVideoPlayer
    },
    data(){
        return{
            mapKey: 0,
            plotKey: 0,

            // coordinates of adelaide as default
            userLat: -34.9285,
            userLng: 138.6007,
            mapCenter:{
                'lat': -34.9285,
                'lng': 138.6007
            },

            optionsExpanded: false,
            searchRadius: 1000000, // search radius around user location
            alertsInRadius: [],
            timePeriods:{
                "1 hour": 1,
                "1 day": 24,
                "3 days": 24 * 3,
                "1 week": 24 * 7,
                
            },
            selectedHours : 24,

            cameraDeployments: [],
            deviceSearchRadius: 1000, // used to search devices around a camera
            devicesToDisplay: [],
            currentAlerts: {}, // alerts of each deployment displayed

            // to be deprecated, use environmentalData
            sensorData: {}, // data of each selected sensor

            dataTypes: [], // list of data types, note: myriota only has temperature, humidity and moisture
            selectedDataType: {}, 
            environmentalData:{},
            myriotaData:{},
            
            dataLoading:{},

            selectedDeviceDeployment: {}, // selected deployment to graph

            plotDeployments: []
            
        }
    },
    computed:{
        infoSectionHeight(){  
            return 400;      
            //return 100 + this.selectedDeviceDeployment.device.sensors.length * 300;
        },
       
    },
    watch:{
        // center the map on user's location
        userLat(newVal, oldVal){
            this.viewLocationOnMap(newVal, this.userLng);
        },
        userLng(newVal, oldVal){
            this.viewLocationOnMap(this.userLat, newVal);
        },
        selectedHours(newVal, oldVal){
            // needs to reset bc getCurrentAlerts() doesn't overwrite the existing array
            this.currentAlerts = [];
            this.getCurrentAlerts();
            this.getAlertsInArea();
            this.updateEnvironmentalData();
            this.updateMyriotaData();
        },
        selectedDataType(newVal, oldVal){

            this.updateEnvironmentalData();
            this.updateMyriotaData();
        }
    },
    methods:{
        updateMap(){
            this.mapKey++;
        },
        updatePlot(){
            // this could potentially get very big and causes an overflow?
            this.plotKey++;
            //console.log(this.plotKey)
            
        },
        viewLocationOnMap(lat, lng){
            this.mapCenter = {
                'lat': lat,
                'lng': lng
            };
        },
        getDataTypes(){
            snailService.getDataTypes()
                .then(res => this.dataTypes =res)
                .finally(() =>{
                    if(this.dataTypes){
                        this.selectedDataType = this.dataTypes[0]; // default to first one
                    }
                });
        },


        // get the list of currently deployed cameras for the map to display
        getCurrentCameras(){
            snailService.getCurrentCameraDeployments()
                .then(res => this.cameraDeployments = res)
                .finally(()=>{
                    this.getCurrentAlerts();
                    this.updateMap();
                    
                });
        },

        // for the map
        getCurrentAlerts(){
            this.cameraDeployments.forEach((deployment) => {
                // default 1km, 1 day for now
                snailService.getRecentAlerts(deployment.latitude, deployment.longitude, 1000, this.selectedHours)
                .then(res => {
                    // this is for when one device have two deployments displayed (for some reason)
                    // will refactor the endpoint to only query using deviceId
                    if(this.currentAlerts[deployment.camera.deviceId]== null){
                        this.currentAlerts[deployment.camera.deviceId] = res;
                    }
                    else{
                        this.currentAlerts[deployment.camera.deviceId] = 
                        this.currentAlerts[deployment.camera.deviceId].concat(res);
                    }
                    
                })
                .finally(()=>{
                    //console.log(this.currentAlerts)
                })
            });
            
  
        },
        // get all device deployments around an area to display on the map
        getDevicesToDisplay(lat, lng){
            // snailService.getDevicesInArea(lat, lng, this.deviceSearchRadius)
            //     .then(res => this.devicesToDisplay = res)

            // TODO: change to myriota?
        },
        
        // new: get all myriota devices instead
        getCurrentMyriotaDevices(){
            snailService.getCurrentMyriotaDeployments()
                .then(res => this.devicesToDisplay = res)
                .finally(()=>{
                    this.updateMap();
                    
                });
        },

        // to be deprecated
        async getSensorData(deployment){
            for (var sensor of deployment.device.sensors){
                await snailService.getSensorData(sensor.id, deployment.id)
                    .then(res => this.sensorData[deployment.id][sensor.id] = res)
                //break;
            }
            
        },

        async getMyriotaData(deployment){
            const terminalId = deployment.device.terminalId;
            var currentTime = new Date();
            var startTime = new Date();
            startTime = startTime.setHours(currentTime.getHours() - this.selectedHours);
            this.dataLoading[terminalId] = true
            await snailService.getMyriotaData(terminalId, startTime, currentTime, this.selectedDataType.id)
                    .then(res => this.myriotaData[terminalId] = res)
                    .finally(()=> this.dataLoading[terminalId] = false);
            
        },

        async getEnvironmentalData(deployment){
            const deviceId = deployment.camera.deviceId;
            this.dataLoading[deviceId] = true
          
            await snailService.getRecentEnvironmentalData(deviceId,this.selectedHours,this.selectedDataType.id)
                    .then(res => this.environmentalData[deviceId] = res)
                    .finally(()=> this.dataLoading[deviceId] = false);

        },
        async updateMyriotaData(){
            // TODO: not sure if these would need to be changed in the future
            for (var terminalId in this.myriotaData){
                this.dataLoading[terminalId] = true
                var currentTime = new Date();
                var startTime = new Date();
                startTime = startTime.setHours(currentTime.getHours() - this.selectedHours);
                await snailService.getMyriotaData(terminalId, startTime, currentTime, this.selectedDataType.id)
                    .then(res => this.myriotaData[terminalId] = res)
                    .finally(()=> this.dataLoading[terminalId] = false);

            }

        },
        async updateEnvironmentalData(){
            // TODO: not sure if these would need to be changed in the future
            for (var deviceId in this.environmentalData){
                this.dataLoading[deviceId] = true
          
                await snailService.getRecentEnvironmentalData(deviceId,this.selectedHours,this.selectedDataType.id)
                    .then(res => this.environmentalData[deviceId] = res)
                    .finally(()=> this.dataLoading[deviceId] = false);

            }

        },
        // for the summary
        getAlertsInArea(){
            snailService.getRecentAlerts(this.userLat, this.userLng, this.searchRadius, this.selectedHours)
                .then(res => this.alertsInRadius = res)
        },
        getUserLocation(){
            const success = (position) => {
                this.userLat  = position.coords.latitude;
                this.userLng = position.coords.longitude;
                this.getAlertsInArea();
            };
            const error = (err) => {
                console.log(err)
            };

            // This will open permission popup
            navigator.geolocation.getCurrentPosition(success, error);
        
        },

        async getLocationName(deployment){
            var result = await snailService.reverseGeocoding(deployment.longitude, deployment.latitude);
           
            if(!result || result['features'].length ===0){
                deployment['locationName']= '';
                return;
            }

            deployment['locationName']= result['features'][0]['text'];

        },

        onCameraClicked(camera){
            //this.getCurrentAlerts(id);
            this.getDevicesToDisplay(camera.latitude, camera.longitude);
        },
        onViewGraph(deployment){
            if(this.plotDeployments.includes(deployment)){
                return;
            }
            // console.log("ALERTS: ")
            // console.log(this.currentAlerts)
            // console.log(deployment)
            this.getLocationName(deployment);
            this.plotDeployments.push(deployment);
            
            if(deployment.type == 'sensor'){
                
                //this.sensorData[deployment.id] = {}
                //this.selectedDeviceDeployment = deployment;
                //this.getSensorData(deployment);

                this.getMyriotaData(deployment);
            }
            if(deployment.type === 'camera'){
                this.getEnvironmentalData(deployment);
            }
            
        },
    },
    mounted(){
        if(snailService.isLoggedIn()){
            this.getDataTypes();
            this.getCurrentCameras();
            this.getCurrentMyriotaDevices();
            this.getUserLocation();
        }
        
    }
}
</script>

<style>

    .info-section{
  
        border-radius: 10px;
        min-width: 350px;
        height: auto;
        left:80vw;
    
        background-color: rgba(255, 255, 255, 0.45);
        float:right;
        margin-top: 10vh;
        
        z-index: 2;

        padding: 10px;
        display: grid;
        backdrop-filter: blur(20px);
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }

    .summary-section{
        
        border-radius: 10px;
        width: 350px;
        max-height: calc(100vh - 250px);
        height: auto;
        /* left:80vw; */

        background-color: rgba(255, 255, 255, 0.45);
        float:right;
        margin: 20px;
        margin-top: 120px;
        
        z-index: 2;
      
        padding: 10px;
        padding-top: 30px;
        /* margin: 5%; */
        display: grid;
        backdrop-filter: blur(20px);
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

        overflow-y: overlay;
    }

    .info-card{

        border-radius: 10px;
        min-width: 300px;
        left:80vw;
    
        /*background-color: rgba(255, 255, 255, 0.45);*/
        float:right;
        /*margin-bottom: 10px;*/
        
        z-index: 2;
        /*box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);*/
        padding: 20px;
        padding-bottom: 0px;
        text-align: left;

        list-style: none;
        /*backdrop-filter: blur(30px);*/
    }
    .h3{
        margin-top: 10px;
    }
    .map-container{
        z-index: -1;
        position: absolute;
        
        width: calc(100vw - 0px);;
    
    }
    .alerts-container{
        height: 300px;
        overflow-y: overlay;
    }
    .options-container{
        display: grid;
        grid-template-columns: auto auto ;
    }

    .coords-input {
        width: 80px;
        
    }
    .expand-btn {
    background-color: #ffffff00;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    }
    
    .expand-icon {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;

        display: inline-block;
    }

    .expand-icon-down {
        border-top: 6px solid #000;
    }
    
    .expand-icon-up {
        border-bottom: 6px solid #000;
    }
    .graph-button-container {
            display: flex;
            justify-content: space-between;
        }
    

</style>
