<template>
    <div class="page-container">
      <!-- Sidebar -->
      <div class="sidebar">
        <h2>Snail Videos</h2>
        <hr class="divider" />
        <div>
          
            <b-form-select 
                v-model="selectedCamera" 
                
                size="sm" 
                class="mt-3">
                <option :value="null" disabled>-- Please select a camera --</option>
                <option v-for="camera in cameras" :key="camera.id" :value="camera">
                    {{ camera.description }}
                </option>
            </b-form-select>
           
        </div>
        <div class="camera-id-picker">
            
            <!-- <label for="camera-id-input" class="date-label">Camera Id:</label>
            <input id="camera-id-input" v-model="cameraId" type="number" class="camera-id-input"> -->
        </div>
        <div class="date-pickers">
        <div class="date-picker">
            <label for="start-date" class="date-label">Start Time:</label>
            <VueDatePicker id="start-date" v-model="startDate" :enable-time-picker="false" class="date-input" />
        </div>
        <div class="date-picker">
            <label for="end-date" class="date-label">End Time:</label>
            <VueDatePicker id="end-date" v-model="endDate" :enable-time-picker="false" class="date-input" />
        </div>
        </div>

        <b-form-checkbox
            id="checkbox-1"
            v-model="showAnnotated"
            name="checkbox-1"
        
            >
            Show annotated video
        </b-form-checkbox>
        <hr class="divider" style="margin-top: 50px;"/>
        <h5>Select Data Types</h5>
        <div>

        </div>
        <div v-for="dataType in dataTypes" :key="'dataType' + dataType.id">
            <b-form-checkbox 
            v-if="!dataType.hidden"
            :id=" 'dataType' + dataType.id"

            v-model="dataType.selected"
            @change="getEnvironmentalData()"
            >
            {{dataType.name}}
            </b-form-checkbox>
        </div>
        
        

        

      </div>
      
      <!-- Main Content -->
      <div class="main-content">
        <!-- <h1 class="page-title">Snail Videos from Camera #{{ cameraId }}</h1>
        <hr class="divider" /> -->
        <h3 v-if="!videos || videos.length == 0">Sorry, no videos found. Try adjusting your filters.</h3>
        <div v-if="loading" >
            <h3>Loading videos...</h3>
            <b-spinner style="width: 3rem; height: 3rem;" label="Spinning"></b-spinner>
        </div>
        <div class="videos-container">
            <div class="focused-section">
                <div v-if="focusedVideo" class="focused-video">
                    <video
                        :id="'video' + focusedVideo.id" 
                        v-if="focusedVideo.id !== surveyVideoId"
                        :key="videoKey + focusedVideo.id"
                        controls
                        muted
                        class="video"
                    
                    >
            
                    <source :src=" focusedVideo ? `${showAnnotated? focusedVideo.altURL : focusedVideo.url}` : ''" type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>
                    <div class="video-info">
                        Video #{{ focusedVideo.id }}
                        <span class="timestamp">{{ formatDate(focusedVideo.endTime) }}</span>
            
                    </div>
                    

                </div>
                <div v-if="focusedVideo" >
                    <div v-if="dataLoading" >
                        <h3>Loading data...</h3>
                        <b-spinner style="width: 3rem; height: 3rem;" label="Spinning"></b-spinner>
                    </div>
                    <div v-for="dataType in dataTypes" :key="'graph' + dataType.id">
                        <DataPlot v-if="dataType.selected && !dataType.hidden && dataType.id!='4'" class="focused-data" 
                            :data="{
                                'values':environmentalData[dataType.id], 
                                'info': {                     
                                    'title': '',
                                    'type': dataType.name,
                                    'unit': dataType.unit
                                }, 
                                'plotField': 'value',
                            }"
                            :plotId="'dataType#' + dataType.id + graphsKey"
                            :hoveredX="currentTime"
                            @plotClicked="onPlotClicked"
                        />
                        <WindChart v-if="dataType.selected && dataType.id=='4'" class="focused-data" 
                            :data="{
                                // ids should be fixed 
                                windSpeeds:environmentalData['4'],
                                windDirections:environmentalData['6'],
                                // enable user to define this?
                                speedCategories: speedCategories,
                                info: {                     
                                    'title': '',
                                    'type': dataType.name,
                                    'unit': dataType.unit
                                }, 
                                plotField: 'value',
                                
                            }"
                            :plotId="'scatterPlot' + graphsKey"
                            plotType="scatter"
                            :hoveredX="currentTime"
                            @plotClicked="onPlotClicked"
                        />
                        
                        <WindChart v-if="dataType.selected && dataType.id=='4'" class="focused-data" 
                            :data="{
                                // ids should be fixed 
                                windSpeeds:environmentalData['4'],
                                windDirections:environmentalData['6'],
                                // enable user to define this?
                                speedCategories: speedCategories,
                                info: {                     
                                    'title': '',
                                    'type': dataType.name,
                                    'unit': dataType.unit
                                }, 
                                plotField: 'value',
                             
                            }"
                            :plotId="'rosePlot' + graphsKey"
                            plotType="rose"
                            :hoveredX="currentTime"
                            @plotClicked="onPlotClicked"
                        />
                    </div>
                

                </div>
                    

            </div>
            
        
            <div class="video-list">
                <div v-for="video in videos.filter(v=>(!focusedVideo || v.id !== focusedVideo.id))" :key="video.id" class="video-item">
                
                    <SnailSurvey
                        :videoId="video.id"
                        v-if="video.id === surveyVideoId"
                        @close="closeSurvey"
                    />
                    <video
                        :id="'video' + video.id" 
                        v-if="video.id !== surveyVideoId"
                        :key="videoKey + video.id"
                        controls
                        muted
                        class="video"
                        @click="selectNewVideo(video)"
                    
                    >
                    <!-- @ended="showSurvey(video.id)" -->
                    <!-- TODO: change this based on base url -->
                    <source :src=" video ? `${showAnnotated? video.altURL : video.url}` : ''" type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>
                    <div class="video-info">
                        Video #{{ video.id }}
                        <span class="timestamp">{{formatDate(video.endTime)  }}</span>
            
                    </div>
                    
                    <b-button
                        class="btn btn-outline"
                        @click="clickedDownload(showAnnotated? video.altURL : video.url)"
                        v-b-tooltip.hover
                        title="Download Video"
                        style="float: right;"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                <path fill="currentColor" d="m12 16l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11zm-6 4q-.825 0-1.412-.587T4 18v-3h2v3h12v-3h2v3q0 .825-.587 1.413T18 20z" />
                            </svg>
                    </b-button>
                </div>
            </div>

        </div>
        
      </div>
    </div>
  </template>
  
  


<script>
import SnailService from "../services/SnailService";
import VueDatePicker from '@vuepic/vue-datepicker';
import SnailSurvey from '@/components/SnailSurvey.vue';
import '@vuepic/vue-datepicker/dist/main.css';
import router from "@/router";
const snailService = new SnailService();
const FPS = 20 // frames per second of the videos
const PHOTO_FREQ = 1000 * 60 // one minute per photo, in miliseconds
const VID_SECOND_TO_MILLISECONDS_REAL = FPS * PHOTO_FREQ

import DataPlot from '@/components/DataPlot.vue';
import WindChart from '@/components/WindChart.vue';
export default{
    name: 'SnailVidoesView',
    components: { 
        VueDatePicker,
        SnailSurvey,
        DataPlot,
        WindChart
    
    },
   
    data(){
        return{
            // TODO: should get this from backend
            dataTypes:[
                {
                    id: '0',
                    name: 'Temperature',
                    unit: '°C',
                    hidden: false,
                    selected: false
                },
                {
                    id: '1',
                    name: 'Humidity',
                    unit: '%',
                    hidden: false,
                    selected: true
                },
                {
                    id: '2',
                    name: 'Moisture',
                    unit: '%', // need to confirm this
                    hidden: false,
                    selected: false
                },
                {
                    id: '3',
                    name: 'VWC',
                    unit: '°C',
                    hidden: true,
                    selected: false
                },
                {
                    id: '4',    
                    name: 'Wind',
                    unit: 'm/s',
                    hidden: false,
                    selected: true
                },
                {
                    id: '5',
                    name: 'Wind (km/h)',
                    unit: 'km/h',
                    hidden: true,
                    selected: false
                },
                {
                    id: '6',
                    name: 'WindDir',
                    unit: '',
                    // this makes it always loaded
                    // TODO: might need to optimise
                    hidden: true,
                    selected: true
                },

                {
                    id: '7',
                    name: 'WindDirRaw',
                    unit: '',
                    hidden: true,
                    selected: false
                },
                {
                    id: '10',
                    name: 'Rainfall',
                    unit: 'mm',
                    hidden: false,
                    selected: false
                },


            ],
            
            // for wind speeds
            speedCategories: [
                {
                    max: 0.5,
                    min: 0,
                    name: '0 - 0.5 m/s',
                    color: '#B4F5FF' // Light blue
                },
                {
                    max: 1.5,
                    min: 0.5,
                    name: '0.5 - 1.5 m/s',
                    color: '#6495ED' // Cornflower blue
                },
                {
                    max: 3.5,
                    min: 1.5,
                    name: '1.5 - 3.5 m/s',
                    color: '#7FFF00' // Chartreuse
                },
                {
                    max: 6,
                    min: 3.5,
                    name: '3.5 - 6 m/s',
                    color: '#FFFF00' // Yellow
                },
                {
                    max: 8,
                    min: 6,
                    name: '6 - 10 m/s',
                    color: '#FF4500' // Orange red
                },
                {
                    max: 99999,
                    min: 8,
                    name: '10+ m/s',
                    color: '#8B0000' // Dark red
                }
            ],

            videos: [],
            cameras:[],
            selectedCamera: null,
            // start with 4 days ago
            startDate: new Date(Date.now() - 5 * 24 * 60 * 60 * 1000).setHours(23, 59, 59, 999),
            endDate: new Date(Date.now()).setHours(23, 59, 59, 999),
            
            surveyVideoId: 0, // the id of the video being surveyed on
            videoKey:0,
            cameraId:0,

            loading: false,
            dataLoading:false,
            showAnnotated: false,

            environmentalData: {},
            graphsKey: 0,

            focusedVideo:null,
            
            // current time in the focused video (offset by the timezone diff.), to sync hovered locations on the plots with the video
            currentTime: new Date().getTime(),   // The plot is treating UTC as local time i.e. it thinks it's plotting UTC times, even though they are local
                             // to make it hover at the correct time, convert the time to local time and pretend it's UTC 
                             // e.g. to hover at 3-27 6:07 local time on the graphs: new Date("2024-03-27T06:07:12.000Z").getTime() 
            lastClickedTime: new Date().getTime()
            
        }
    },
    watch:{
        showAnnotated:{
            handler(newVal, oldVal){
                this.updateVideoElements();

                // trigger the focusedVideo watcher
                var temp = this.focusedVideo
                this.focusedVideo = null
                this.focusedVideo = temp

            },
        },
        selectedCamera:{
            handler(newVal, oldVal){
                this.cameraId = newVal.id;

            },
            deep: true
        },
        cameraId:{
            // updates the video
            handler(newVal, oldVal){
                this.getVideos();

            },
        },
        startDate:{
            // updates the video
            handler(newVal, oldVal){
                this.getVideos();

            },
        },
        endDate:{
            // updates the video
            handler(newVal, oldVal){
                this.getVideos();

            },
        },
        lastClickedTime:{
            // this is not working on Safari?
            handler(newVal, oldVal){
                if(!this.focusedVideo){
                    return;
                }
                const videoEle = document.getElementById('video' + this.focusedVideo.id);
                // the times should always be in UTC, despite the timezone labels (or lack thereof)
                // can remove this once fixed in the backend
                var processedStart = this.focusedVideo.startTime.split("+")[0] + "Z";
                
                var secondsPassed = (newVal - new Date(processedStart).getTime())/VID_SECOND_TO_MILLISECONDS_REAL;

                if(secondsPassed > 0){
                    videoEle.currentTime = secondsPassed;
                }
                
            }
        },
     
        focusedVideo: {
            handler(newVal, oldVal){
                if(!newVal){
                    return;
                }
                this.currentTime = 0; //reset the highlighted time

                //console.log(newVal)
                // wait for the elements to update
                // probably have better solutions that this
                setTimeout(() => {
                    // to sync hovered locations on the plots with the video
                    const videoEle = document.getElementById('video' + newVal.id);
                    if(videoEle){
                        videoEle.addEventListener("timeupdate",() =>{
                            
                            // the times should always be in UTC, despite the timezone labels (or lack thereof)
                            // can remove this once fixed in the backend
                            var processedStart = newVal.startTime.split("+")[0] + "Z"
                           
                            const secondsStarted = videoEle.currentTime;
                            // The plot is treating UTC as local time i.e. it thinks it's plotting UTC times, even though they are local
                            // to make it hover at the correct time, convert the time to local time and pretend it's UTC 
                            this.currentTime = new Date(processedStart).getTime() + secondsStarted * VID_SECOND_TO_MILLISECONDS_REAL - new Date().getTimezoneOffset() * 1000 * 60 * 1
                            // console.log(new Date().getTimezoneOffset())
                            // console.log(new Date(this.currentTime)); 
                        });
                    }
                }, 500);
                
            },
            deep: true
            
        }
        
    },
    methods:{
        updateVideoElements(){
            this.videoKey++;
            
        },
            
        updateGraphs(){
            this.graphsKey++;
        },
        showSurvey(videoId){
       
            this.surveyVideoId = videoId;
        },
        closeSurvey(){
            this.surveyVideoId = 0;
        },
        getVideos(){
            // TODO: add start and end
            this.focusedVideo = null;
            this.videos = [];
            this.loading = true;
            snailService.getVideos(this.cameraId, this.startDate, this.endDate)
                // description is the start date of the night
                .then(res => this.videos = res.sort((a, b) => b.description.localeCompare(a.description))) // most recent first
                .finally(()=> {
                    this.loading = false;
                    if(this.videos){
                        this.focusedVideo = this.videos[0];
                        this.getEnvironmentalData();
                    }
            
                    this.updateVideoElements()
                });
        },
        getCameras(){
            snailService.getCameras()
                .then(res => {

                    this.cameras = res.sort((a, b)=>{
                        return a.description.localeCompare(b.description);
                    });
                 
                    // sync dropdown select
                    for(let i=0; i<this.cameras.length; i++){

                        if(this.cameras[i].id == this.cameraId){
                            this.selectedCamera = this.cameras[i];
                            break;
                        }
                    }
                });
        },
        getEnvironmentalData(){
            if(!this.selectedCamera || !this.focusedVideo){
                return;
            }
            
            const deviceId = this.selectedCamera.deviceId
            // past 24 hours for now, need to get video start/end times
            this.dataTypes.forEach(dataType => {
                
                
                if(dataType.selected){
                    this.dataLoading = true
                    // the times should always be in UTC, despite the timezone labels (or lack thereof)
                    var processedStart = this.focusedVideo.startTime.split("+")[0] + "Z"
                    var processedEnd = this.focusedVideo.endTime.split("+")[0] + "Z"
                    snailService.getEnvironmentalData(deviceId, new Date(processedStart), new Date(processedEnd), dataType.id)
                        .then(res => this.environmentalData[dataType.id] = res)
                        .finally(()=> {
                            this.updateGraphs();
                            this.dataLoading= false;
                        });
                }
                
            });
           

        },
        formatDate(timestamp) {
            if (!timestamp) return '';
            const dateObj = new Date(timestamp.split("+")[0] + "Z"); // process as utc (probably needs to be fixed in backend4)
            const yesterday = new Date(dateObj - 86400000); //milisecs in a day
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            return `${yesterday.toLocaleDateString(undefined, options)} - ${dateObj.toLocaleDateString(undefined, options)}`;
        },
        getBaseUrl(){
            //console.log(window);
            return window.location.origin;
        },
        selectNewVideo(video){
            this.focusedVideo = video;
            this.getEnvironmentalData();
            
           
        },
        onPlotClicked(data){    
            this.lastClickedTime = new Date(data.points[0].x).getTime()
        },
        async clickedDownload(fileName){
        
            try {
                const response = await fetch(fileName)
                const blob = await response.blob();
                const url = await URL.createObjectURL(blob)

                const a = document.createElement("a");
                a.href = url;
                a.download = "snail_video.mp4";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } catch(err) {
                console.log({ err })
            }
        }

         
    },

    created(){
        this.cameraId = this.$route.params.cameraId;
        if(snailService.isLoggedIn()){
          this.getVideos();
          this.getCameras();
        }
        
    },
  
}

</script>

<style scoped>
.page-container {
  display: flex;
  align-items: stretch; /* Make content and sidebar same height */
}

.sidebar {
    border-right: 1px solid #000000;
    border-bottom: 1px solid #000000;
  
    padding: 20px;
    background-color: white;
    align-items: center;
 
}


.sidebar h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.main-content {
  flex: 1; /* Fill the remaining space */
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #000000;
  overflow: auto;
  max-height: calc(100vh - 120px);
}
.video-container {
  width: 100%;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f7f7f7;
}

.page-title {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
 
}

.camera-id-picker{
    margin: 20px;
}

.camera-id-input{
    margin:10px;
    width:50px;
}

.date-pickers {
  display: table;
  /* justify-content: center; */
  /* align-items: center; */
  margin-bottom: 20px;
}

.date-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  font-size: 16px;
  color: #555;
}

.date-label {
  font-weight: bold;
  margin-bottom: 10px;
}

.date-input {
  width: 200px;
  padding: 5px;
  /* border: 1px solid #ccc;
  border-radius: 4px; */
  font-size: 14px;
}

.divider {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 30px;
  /* border: 1px solid #ccc; */
}

.video-list {
    flex: 1; 
    display: grid;
    margin: 20px;
  
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    min-width: 300px;
    max-width: 400px;
    max-height: 200vh;
    overflow: auto;
}

.videos-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.video-item {

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    /* border: 1px solid #ddd; */
    /* border-radius: 6px; */
    padding: 10px;
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
    transition: transform 0.2s;
    cursor: pointer;
    height: 300px;
    margin-bottom: 50px;
}

.focused-section{
    flex: 3; 
    min-width: 800px;
    padding: 50px;
    padding-top: 10px; 
}

.focused-video{
    margin:auto;
    margin-top: 10px;
    width: 75%;
    /* max-height: 50vh; */
}

.focused-data{
    margin:auto;
    /* height: 10vh; */
}

.video-item:hover {
  transform: translateY(-5px);
}

.timestamp {
  font-style: italic;
  margin-top: 10px;
  font-size: 14px;
  color: #777;
}

.video {

  outline: none;
  border: 1px solid #ddd; 
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
  border-radius: 10px; 
  width: 100%;
  height: auto;
}
</style>
